import React, { useRef, useLayoutEffect } from "react";
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Formulario from "../components/Formulario"


import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import animateReveal from "../components/gs_reveal";


export default function ReformaCocina() {

    gsap.registerPlugin(ScrollTrigger);

    const revealRefs = useRef([]);
    revealRefs.current = [];

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        let scrollTriggers = [];
        scrollTriggers = animateReveal(revealRefs.current);
        return () => {
            scrollTriggers.forEach((t) => t.kill(true));
        };
    }, []);

    const addToRevealRefs = (el) => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };
    // end gsap

    return (
        <Layout>
            <Helmet
                title="Empresa de reformas | Pide presupuesto ya"
                meta={[
                    { name: 'description', content: 'Reformas.Top es una empresa de reformas integrales. Pide presupuesto gratis y sin compromiso.' },
                ]}
            />
            <div className="bg-[url('https://reformas.top/background/reformas-cocina.webp')] bg-fixed bg-cover bg-bottom bg-no-repeat">
                <div className=" container flex flex-col xl:flex-row justify-between py-5">
                    <div className=" flex items-center justify-center 2xl:px-10">
                        <h1 className="text-white text-center xl:text-left text-4xl tracking-tight sm:text-6xl lg:text-6xl 2xl:text-7xl leading-[1.2] mb-10">
                            Reformas de Cocinas Pequeñas y Baratas en
                        </h1>
                    </div>
                    <div>
                        <Formulario />
                    </div>
                </div>
            </div>

            <section className="container mt-20">
                <div className="max-w-4xl mx-auto mb-40">
                    <p className=" text-text text-xl text-center">Para tener la cocina de tus sueños puede que necesites
                        reformarla toda, o tal vez solo hagan falta algunas renovaciones. De cualquier manera, en Reformas.Top podrás
                        encontrar los mejores precios para realizar las
                        modificaciones necesarias y darle una nueva imagen a este rincón tan especial de la casa.</p>
                </div>
                <div className="flex flex-col lg:flex-row">
                    <div className="lg:w-1/2">
                    <StaticImage className="w-full object-cover rounded-3xl shadow-md" src="../images/reformas-de-cocinas.webp" alt="Reformas de Cocinas" />
                    </div>
                    <div className="lg:w-1/2 lg:pl-10 flex flex-col justify-center">
                        <h2 className="mt-6 text-5xl text-text font-semibold">Reformas de Cocinas
                        </h2>
                        <p className="mt-6 text-text text-xl">Si buscas una empresa de reformas en ciudad para hacer algunos
                            cambios en tu cocina, ¡no busques más, ya encontraste al equipo ideal! Aquí tienes algunas fotos para que
                            lo compruebes y dejes volar tu imaginación con las ideas y diseños que también podrían funcionar a la
                            perfección en tu casa.</p>
                    </div>
                </div>
                <div className="py-20">
                    <h2 className="mt-6 text-5xl text-text font-semibold text-center mb-20">Reformas de Cocinas Pequeñas</h2>
                    <div className="mx-auto max-w-6xl  mb-20">
                        <StaticImage className="rounded-3xl shadow-md" src="../images/reformas-de-cocinas-pequeñas.jpg" alt="Reformas de Cocinas Pequeñas" />
                    </div>
                    <div className="max-w-4xl text-center mx-auto">
                        <p className="mb-5">Las reformas de cocinas pequeñas en ciudad son perfectas para darle un nuevo aire a
                            cualquier hogar, y en
                            Reformas.Top hacemos todo tipo de reformas a un excelente precio para que nuestros clientes puedan tener la
                            cocina de sus sueños. </p>
                        <p>Las renovaciones que realizamos están pensadas en función de lo que resulta más
                            conveniente y práctico para cada caso, incluso para el piso en general, como puedes ver en
                            https://reformas.top/pisos/ por si buscas remodelar todo tu apartamento. En el caso de las cocinas
                            pequeñas, puede cambiarse la distribución de la estancia para aprovechar mejor el espacio. También, se puede
                            colocar una ventana o una puerta corrediza de vidrio que permita la entrada de luz para que la cocina tenga
                            una iluminación más natural y parezca un poco más grande.
                        </p>
                    </div>
                </div>
                <div className="pb-20">
                    <h2 className="mt-6 text-5xl text-text font-semibold text-center mb-20">Reformas de Cocinas Baratas</h2>
                    <div className=" flex flex-col lg:flex-row">
                        <div className="flex flex-col justify-center lg:w-1/2 lg:pr-10 mb-10 lg:mb-0">
                            <p className="mb-5">En Reformas.Top hacemos todo tipo de reformas de cocinas baratas en ciudad al mejor
                                precio, al igual
                                que las reformas de baños por si necesitas remodelar más de un área en la casa y así modernizarla e
                                incrementar su valor en el mercado. Las opciones son muy variadas, desde instalar una barra para desayunos
                                o colocar una isla central, hasta demoler alguna pared para crear un concepto abierto que fluya con
                                naturalidad hacia el comedor o la sala de estar.</p>
                            <p>Otras renovaciones baratas tratan de cambiar los armarios y las encimeras, para conservar la estructura
                                de la cocina y la distribución del espacio, reformar el fregadero y la grifería, cambiar las lámparas o
                                abrir alguna ventana. Sin importar el tamaño de tu cocina, estas modificaciones serán perfectas para darle
                                una imagen diferente.</p>
                        </div>
                        <div className=" flex items-center lg:w-1/2">
                            <StaticImage className="w-full object-cover rounded-3xl shadow-md" src="../images/reformas-cocina-pequena.jpg" alt="Reformas de Cocinas" />
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}